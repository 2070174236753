<template>
  <div class="container">
    <div class="container content">
      <div class="icon-container">
        <v-icon
          class="mdi mdi-alert-circle icon-large"
          size="75"
          color="var(--light-grey)"
        ></v-icon>
      </div>
      <h1 class="title-not-found">403</h1>
      <p class="sub-not-found">Access Forbidden.</p>
      <p class="sub-not-found sub-text">
        You do not have permission to access this page. Please contact the
        administrator for assistance.
      </p>
    </div>
  </div>
</template>
<style>
/* Add this to your existing style block or CSS file */
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust the height as needed to control the icon size */
  padding-top: 40px;
}

/* Page CSS */
.title-not-found {
  color: var(--dark-grey);
  text-align: center;
  font-size: 80px;
  font-family: "roboto";
  margin-top: 20px;
  margin-bottom: 20px;
}

.sub-not-found {
  font-family: "roboto";
  text-align: center;
  color: var(--dark-grey);
}

.sub-not-found.sub-text {
  margin-top: 60px;
}
</style>
<script>
import axios from "axios";
export default {
  components: {},
  title() {
    return `404`;
  },
  data() {
    return {};
  },
};
</script>
